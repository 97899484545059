import React, { useState } from "react";
import { generateColorPaletteWithBaseHex } from "../../helpers/generatePaletteWithBase";
import GridImages from "../GridImages";
import PalettePost from "../PalettePost";
import { button_style } from "./styles.module.css";
import { darkOrLightColor } from "../../helpers/darkOrLightColor";

export const MorePalettesWithColors = ({
  hex,
  locale,
  colorName,
  withInitialPalette = false,
}) => {
  const initialPalette = withInitialPalette
    ? Array.from({ length: 6 }).map(() => generateColorPaletteWithBaseHex(hex))
    : [];

  const [palettes, setPalettes] = useState(initialPalette);

  // const textButton =
  //   locale === "es"
  //     ? `Nuevas paletas de color ${colorName}`
  //     : locale === "en"
  //     ? `New ${colorName} color palettes `
  //     : locale === "pt"
  //     ? `Novas paletas de cores ${colorName}`
  //     : locale === "fr"
  //     ? `Nouvelles palettes de couleurs ${colorName}`
  //     : null;

  const handleNewPalettes = () => {
    const generatePalettes = Array.from({ length: 3 }).map(() =>
      generateColorPaletteWithBaseHex(hex)
    );
    setPalettes([...palettes, ...generatePalettes]);
  };
  // <button
  //       className={button_style}
  //       style={{ backgroundColor: hex, color: darkOrLightColor(hex) }}
  //       onClick={handleNewPalettes}
  //     >
  //       {textButton}
  //     </button>
  return (
    <section>
      <GridImages>
        {palettes.map(palette => (
          <PalettePost palette={palette} />
        ))}
      </GridImages>
    </section>
  );
};
