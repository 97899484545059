import React from "react";
import {
  list_scheme_wrapper,
  container,
  radio,
} from "../../styles/modules/list_scheme.module.css";

const renderSchemesOptions = locale => {
  if (locale === "es") {
    return [
      {
        nameScheme: "monochromatic",
        renderName: "monocromático",
      },
      {
        nameScheme: "complementary",
        renderName: "complementario",
      },
      {
        nameScheme: "analogous",
        renderName: "análogo",
      },
      {
        nameScheme: "triad",
        renderName: "Tríada",
      },
    ];
  }
  if (locale === "en") {
    return [
      {
        nameScheme: "monochromatic",
        renderName: "monochromatic",
      },
      {
        nameScheme: "complementary",
        renderName: "complementary",
      },
      {
        nameScheme: "analogous",
        renderName: "analogous",
      },
      {
        nameScheme: "triad",
        renderName: "triad",
      },
    ];
  }
  if (locale === "pt") {
    return [
      {
        nameScheme: "monochromatic",
        renderName: "monocromático",
      },
      {
        nameScheme: "complementary",
        renderName: "complementar",
      },
      {
        nameScheme: "analogous",
        renderName: "análogo",
      },
      {
        nameScheme: "triad",
        renderName: "Tríade",
      },
    ];
  }

  if (locale === "fr") {
    return [
      {
        nameScheme: "monochromatic",
        renderName: "monochromatique",
      },
      {
        nameScheme: "complementary",
        renderName: "complémentaire",
      },
      {
        nameScheme: "analogous",
        renderName: "analogique",
      },
      {
        nameScheme: "triad",
        renderName: "triade",
      },
    ];
  }

  return [];
};

function ListScheme({ currentScheme, handleChangeScheme, locale }) {
  const schemes = renderSchemesOptions(locale);

  return (
    <div className={list_scheme_wrapper}>
      {schemes.map(scheme => (
        <div className={container} key={scheme.nameScheme}>
          <input
            onChange={handleChangeScheme}
            type="radio"
            value={scheme.nameScheme}
            id={scheme.nameScheme}
            checked={scheme.nameScheme === currentScheme ? true : false}
          />
          <label className={radio} htmlFor={scheme.nameScheme}>
            <span>{scheme.renderName}</span>
          </label>
        </div>
      ))}
    </div>
  );
}

export default ListScheme;
