import React, { useContext } from "react";
import { generateColorsSChemes } from "../../helpers/generateColorsSchemas";
import Colors from "./Colors";
import ListScheme from "./ListScheme";
import { convertRenderColor } from "../../helpers/convertRenderColor";
import { ContextFormatColor } from "../../context/ContextFormatColor";

import {
  scheme_colors_wrapper,
  scheme_title,
} from "../../styles/modules/scheme_colors.module.css";
import AdManaWithPlaceholder from "../Ads/AdManaWithPlaceHolder";
// import AdWithPlaceholder from "../Ads/AdWithPlaceholder";

// import { ContextLocale } from "../../context/ContextLocale";

function SchemesColors({ color, hexColor, height = "90vh", locale }) {
  // const { lang } = useContext(ContextLocale);
  const [scheme, setScheme] = React.useState("monochromatic");
  const { format } = useContext(ContextFormatColor);

  const handleChangeScheme = e => {
    setScheme(e.target.value);
  };

  const renderPalette = generateColorsSChemes(hexColor, scheme).map(color =>
    convertRenderColor({ color: color }, format)
  );

  const schemeTitle =
    locale === "es"
      ? `Esquemas del color ${color}`
      : locale === "en"
      ? `${color} color schemes`
      : locale === "pt"
      ? `Esquemas de cores ${color}`
      : locale === "fr"
      ? `Schémas de couleurs ${color}`
      : null;

  return (
    <section>
      <h2>{schemeTitle} </h2>
      <div className={scheme_colors_wrapper} style={{ height: height }}>
        <div>
          <ListScheme
            locale={locale}
            currentScheme={scheme}
            handleChangeScheme={handleChangeScheme}
          />
        </div>

        <Colors palette={renderPalette} />
      </div>
      <AdManaWithPlaceholder
        path="/22250597679/ptdc_banner_bottom"
        size={[
          [300, 250],
          [970, 250],
          [728, 250],
          [970, 90],
          [728, 90],
        ]}
        id="ptdc_banner_bottom"
      />
      {/* <AdWithPlaceholder
        ins={`<!-- ptdc-color -->
        <ins class="adsbygoogle"
             style="display:block"
             data-ad-client="ca-pub-3833126112632805"
             data-ad-slot="5789449190"
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>`}
      /> */}
    </section>
  );
}

export default SchemesColors;
