import chroma from "chroma-js";

export function generateColorPaletteWithBaseHex(baseHex) {
  // Verificar si el color base es válido
  if (!chroma.valid(baseHex)) {
    throw new Error("Invalid base color");
  }

  // Color base
  const baseColor = chroma(baseHex);

  // Variante cercana al color base con un ajuste aleatorio de brillo
  const brightnessAdjustment = Math.random() * 2 - 1; // Entre -1 y 1
  const closeVariant = baseColor.brighten(brightnessAdjustment).hex();

  // Generar dos colores aleatorios
  const randomColor1 = chroma.random();
  const randomColor2 = chroma.random();

  // Crear una escala de colores pasando por los colores aleatorios
  const scale = chroma
    .scale([closeVariant, randomColor1, randomColor2])
    .mode("lch");

  // Seleccionar tres colores de la escala
  const complementaryColors = scale.colors(4);

  // Combinar todos los colores en una paleta
  return [baseColor.hex(), ...complementaryColors];
}

// Ejemplo de uso
